<template>
    <el-scrollbar class="seller-scrollbar">
        <el-card class="seller-card">
            <el-breadcrumb slot="header" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">{{ $t('seller.breadcrumb.seller') }}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ name: 'marketingtools' }">{{ $t('seller.breadcrumb.marketPlatform') }}
                </el-breadcrumb-item>
                <el-breadcrumb-item
                    :to="{ name: 'singletreasureList' }">{{ $t('seller.breadcrumb.singletreasure') }}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="seller-panel">
                <div class="panel-steps">
                    <el-steps :active="stepActive" align-center>
                        <el-step v-for="item in $t('seller.singletreasure.stepsOption')" :key="item"
                            :title="item"></el-step>
                    </el-steps>
                </div>
                <el-descriptions class="panel-hd">
                    <el-descriptions-item :label="$t('seller.screen.campaignId')">{{ pageInfo.single_treasure_id }}</el-descriptions-item>
                </el-descriptions>
                <el-descriptions class="panel-bd">
                    <el-descriptions-item :label="$t('seller.screen.campaignTag')">{{ pageInfo.label_name }}</el-descriptions-item>
                    <el-descriptions-item :label="$t('seller.campaign.activityTime')">{{ pageInfo.start_time | parseTime() }} 至 {{ pageInfo.end_time | parseTime() }}</el-descriptions-item>
                    <el-descriptions-item :label="$t('seller.screen.createTime')">{{ pageInfo.create_time | parseTime() }}</el-descriptions-item>
                    <el-descriptions-item :label="$t('seller.screen.couponLevel')">{{ pageInfo.dis_level | typeFilter($t('seller.filters.discountLevel')) }}</el-descriptions-item>
                    <el-descriptions-item :label="$t('seller.formItem.preferentialWay')">{{ pageInfo.dis_type | typeFilter($t('seller.filters.discountType')) }}</el-descriptions-item>
                    <el-descriptions-item :label="$t('seller.screen.campaignName')">{{ pageInfo.name }}</el-descriptions-item>
                </el-descriptions>
                <div class="panel-main">
                    <div class="panel-title">{{ stepActive | typeFilter($t('seller.filters.singletreasureType')) }}</div>
                    <div class="panel-page" v-if="stepActive === 1">
                        <el-form inline size="mini" class="page-query">
                            <el-form-item :label="$t('seller.screen.shopCate') + '：'">
                                <el-select v-model="pageQuery.store_cate_id">
                                    <el-option v-for="item in $t('seller.options.shopCate')" :key="item.value"
                                        :label="item.label" :value="item.value"></el-option>
                                    <el-option v-for="item in cateOptions" :key="item.cate_id" :label="item.cate_name"
                                        :value="item.cate_id"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item :label="$t('seller.combomeal.babyStatus') + '：'">
                                <el-select v-model="pageQuery.goods_status">
                                    <el-option v-for="item in $t('seller.options.babyStatus')" :key="item.value"
                                        :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item :label="$t('seller.screen.babyNameId') + '：'">
                                <el-input v-model="pageQuery.goods_word"
                                    :placeholder="$t('seller.screen.babyNameId')"></el-input>
                            </el-form-item>
                            <!-- <el-form-item :label="$t('seller.formItem.shopCode') + '：'">
                                <el-input v-model="pageQuery.shop_code" :placeholder="$t('seller.formItem.shopCode')"></el-input>
                            </el-form-item> -->
                            <el-form-item>
                                <el-checkbox v-model="pageQuery.is_show_sku" :label="$t('seller.checkbox.skuGoods')"
                                    :true-label="2" :false-label="1"></el-checkbox>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="handleSearch">{{ $t('seller.actions.search') }}</el-button>
                            </el-form-item>
                        </el-form>
                        <el-table v-loading="listLoading" :data="pageList" ref="goodsTable" :row-key="getRowKey"
                            @select="handleTableChange" @select-all="handleTableChangeAll" class="seller-table">
                            <el-table-column type="selection" width="55"
                                :selectable="(row, index) => { return row.single_treasure_id > 0 ? false : true }"
                                :reserve-selection="true">
                            </el-table-column>
                            <el-table-column :label="$t('seller.tableHead.babyDescribe')">
                                <template slot-scope="scope">
                                    <div class="goods-item">
                                        <a :href="$itemBasePath + '/item/' + scope.row.goods_id" class="item-pic" target="_blank">
                                            <img :src="scope.row.default_image" @error="goodsLoadError" alt="">
                                        </a>
                                        <div class="item-main">
                                            <div class="name"><a :href="$itemBasePath + '/item/' + scope.row.goods_id" target="_blank">{{ scope.row.goods_name }}</a></div>
                                            <div class="text">ID：{{ scope.row.goods_id }}</div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column v-if="pageInfo.dis_level === 2" :label="'SKU' + $t('seller.tableHead.number')" prop="spec_count">
                            </el-table-column>
                            <el-table-column :label="$t('seller.tableHead.price')" prop="price">
                                <template slot-scope="scope">￥{{ scope.row.price }}</template>
                            </el-table-column>
                            <el-table-column :label="$t('seller.tableHead.stock')" prop="stock"></el-table-column>
                            <el-table-column>
                                <template slot-scope="scope">
                                    <template v-if="scope.row.single_treasure_id > 0">
                                        <i class="el-icon-warning"></i>
                                        <span v-if="scope.row.single_treasure_id === pageInfo.single_treasure_id">{{ $t('seller.singletreasure.attendedTips1') }}</span>
                                        <span v-else>{{ $t('seller.singletreasure.attendedTips2') }}{{ scope.row.single_treasure_id }}</span>
                                    </template>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="page-bottom">
                            <div class="bottom-hd">
                                <div class="selected">{{ $t('seller.screen.selected') }}{{ pageInfo.dis_level | typeFilter({ 1: '商品', 2: 'SKU' }) }}：{{ totakGoods }} / 1200</div>
                                <pagination v-show="pageTotal > 0" :total="pageTotal" :page.sync="pageCurr"
                                    :limit.sync="pageSize" @pagination="getList"></pagination>
                            </div>
                            <div class="bottom-bd">
                                <el-button type="primary" size="mini" @click="handleNext">{{ $t('seller.actions.nextStep') }}</el-button>
                            </div>
                        </div>
                    </div>
                    <div class="panel-page" v-if="stepActive === 2">
                        <el-table ref="checkTable" :data="checkList" class="seller-table">
                            <el-table-column type="selection" width="55"></el-table-column>
                            <el-table-column :label="$t('seller.tableHead.babyDescribe')">
                                <template slot-scope="scope">
                                    <div class="goods-item">
                                        <a :href="$itemBasePath + '/item/' + scope.row.goods_id" class="item-pic"
                                            target="_blank"><img :src="scope.row.default_image" @error="goodsLoadError"
                                                alt=""></a>
                                        <div class="item-main">
                                            <div class="name"><a :href="$itemBasePath + '/item/' + scope.row.goods_id"
                                                    target="_blank">{{ scope.row.goods_name }}</a></div>
                                            <div class="text">ID：{{ scope.row.goods_id }}</div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('seller.tableHead.unitPrice')" prop="price">
                                <template slot-scope="scope">￥{{ scope.row.price }}</template>
                            </el-table-column>
                            <el-table-column :label="$t('seller.tableHead.stock')" prop="stock"></el-table-column>
                            <el-table-column :label="$t('seller.formItem.preferentialWay')" align="center">
                                <template slot-scope="scope">
                                    <template v-if="pageInfo.dis_level === 1">
                                        <p v-if="pageInfo.dis_type === 1">
                                            {{ $t('seller.singletreasure.discount1') }}<el-input
                                                v-model="postGoods[scope.row.goods_id].dis_value" size="mini"
                                                @input="handleGoodsDiscount($event, scope.row.goods_id)"
                                                oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)"
                                                style="width: 68px;margin: 0 5px;"></el-input>{{ $t('seller.singletreasure.discount2') }}
                                        </p>
                                        <p v-else-if="pageInfo.dis_type === 2">
                                            {{ $t('seller.singletreasure.reduce') }}<el-input
                                                v-model="postGoods[scope.row.goods_id].dis_value" size="mini"
                                                @input="handleGoodsDiscount($event, scope.row.goods_id)"
                                                oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)"
                                                style="width: 68px;margin: 0 5px;"></el-input>{{ $t('seller.unit.yuan') }}
                                        </p>
                                        <p v-else-if="pageInfo.dis_type === 3">
                                            {{ $t('seller.singletreasure.salePrice') + '：' }}<el-input
                                                v-model="postGoods[scope.row.goods_id].dis_value" size="mini"
                                                @input="handleGoodsDiscount($event, scope.row.goods_id)"
                                                oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)"
                                                style="width: 68px;margin-left: 5px;"></el-input>
                                        </p>
                                        <p v-if="errorGoods[scope.row.goods_id] === 1" class="table-error"><i
                                                class="el-icon-circle-close"></i>{{ $t('seller.singletreasure.errorText1') }}
                                        </p>
                                        <p v-else-if="errorGoods[scope.row.goods_id] === 2" class="table-error"><i
                                                class="el-icon-circle-close"></i>{{ $t('seller.singletreasure.errorText2') }}
                                        </p>
                                        <p v-else-if="errorGoods[scope.row.goods_id] === 3" class="table-error"><i
                                                class="el-icon-circle-close"></i>{{ $t('seller.singletreasure.errorText3') }}{{
                                                    scope.row.price }}{{ $t('seller.singletreasure.errorText4') }}
                                        </p>
                                        <p v-else-if="errorGoods[scope.row.goods_id] === 4" class="table-error"><i
                                                class="el-icon-circle-close"></i>{{ $t('seller.singletreasure.errorText5') }}
                                        </p>
                                        <p v-else-if="errorGoods[scope.row.goods_id] === 5" class="table-error"><i
                                                class="el-icon-circle-close"></i>{{ $t('seller.singletreasure.errorText6') }}
                                        </p>
                                    </template>
                                    <template v-else-if="pageInfo.dis_level === 2">
                                        <p>{{ pageInfo.dis_type | typeFilter($t('seller.filters.discountType')) }}</p>
                                        <p>
                                            <el-button type="text" @click="handleDialogVisibleSku(scope)">SKU{{ $t('seller.actions.couponSet') }}</el-button>
                                        </p>
                                    </template>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('seller.tableHead.priceAfterDiscount')" align="center">
                                <template slot-scope="scope">
                                    <p>{{ priceRange(scope.row.goods_id) }}</p>
                                    <p>
                                        <el-button type="text" v-if="postGoods[scope.row.goods_id].erase_status !== 1"
                                            @click="handleGoodsErasure(scope.row.goods_id, 1)">{{
                                                $t('seller.actions.round') }}</el-button>
                                        <el-button type="text" v-else @click="handleGoodsErasure(scope.row.goods_id, 0)">{{
                                            $t('seller.actions.unround') }}</el-button>
                                        <el-button type="text" v-if="postGoods[scope.row.goods_id].erase_status !== 2"
                                            @click="handleGoodsErasure(scope.row.goods_id, 2)">{{
                                                $t('seller.actions.erasure') }}</el-button>
                                        <el-button type="text" v-else @click="handleGoodsErasure(scope.row.goods_id, 0)">{{
                                            $t('seller.actions.unerasure') }}</el-button>
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('seller.tableHead.perLimit')" v-if="pageInfo.dis_level === 1">
                                <template slot-scope="scope">
                                    <el-select v-model="postGoods[scope.row.goods_id].limit" size="mini">
                                        <el-option :label="$t('seller.singletreasure.noLimit')" :value="0"></el-option>
                                        <el-option v-for="item in 5" :key="item" :label="item" :value="item"></el-option>
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('seller.tableHead.handle')">
                                <template slot-scope="scope">
                                    <el-button type="text" @click="handleWithdrawal(scope)">{{
                                        $t('seller.actions.withdrawalActivity') }}</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="page-bottom" style="margin-top: 12px">
                            <el-button size="mini" @click="handlePrev">{{ $t('seller.actions.prevStep') }}</el-button>
                            <el-button type="primary" size="mini" @click="handleSave">{{ $t('seller.actions.save') }}</el-button>
                        </div>
                        <el-dialog :title="dialogTitleSku" :visible.sync="dialogVisibleSku" width="1200px"
                            custom-class="seller-dialog">
                            <el-scrollbar class="dialog-sku" v-if="postGoods[dialogGoodsId]">
                                <div class="dialog-head">
                                    <div v-if="pageInfo.dis_type === 1">
                                        {{ $t('seller.singletreasure.discount1') }}<el-input v-model="dialogDiscount"
                                            size="mini" @input="handleSkuDiscountAll"
                                            oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)"
                                            style="width: 68px;margin: 0 5px;"></el-input>{{ $t('seller.singletreasure.discount2') }}
                                    </div>
                                    <div v-else-if="pageInfo.dis_type === 2">
                                        {{ $t('seller.singletreasure.reduce') }}<el-input v-model="dialogDiscount" size="mini"
                                            @input="handleSkuDiscountAll" oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)"
                                            style="width: 68px;margin: 0 5px;"></el-input>{{ $t('seller.unit.yuan') }}
                                    </div>
                                    <div v-else-if="pageInfo.dis_type === 3">
                                        {{ $t('seller.singletreasure.salePrice') + '：' }}<el-input v-model="dialogDiscount"
                                            size="mini" @input="handleSkuDiscountAll"
                                            oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)"
                                            style="width: 68px;margin: 0 5px;"></el-input>
                                    </div>
                                    <div>
                                        <el-button type="text" v-if="postGoods[dialogGoodsId].erase_status !== 1"
                                            @click="handleDialogErasure(1)">{{ $t('seller.actions.round') }}</el-button>
                                        <el-button type="text" v-else @click="handleDialogErasure(0)">{{
                                            $t('seller.actions.unround') }}</el-button>
                                        <el-button type="text" v-if="postGoods[dialogGoodsId].erase_status !== 2"
                                            @click="handleDialogErasure(2)">{{ $t('seller.actions.erasure') }}</el-button>
                                        <el-button type="text" v-else @click="handleDialogErasure(0)">{{ $t('seller.actions.unerasure') }}</el-button>
                                    </div>
                                </div>
                                <el-table :data="postGoods[dialogGoodsId].spec_list" border class="seller-table">
                                    <el-table-column label="SKU_ID" prop="spec_id"></el-table-column>
                                    <el-table-column label="SKU">
                                        <template slot-scope="scope">
                                            <span style="margin-right: 10px;">{{ scope.row.spec_1 }}</span>
                                            <span>{{ scope.row.spec_2 }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="$t('seller.tableHead.price')"
                                        prop="origin_price"></el-table-column>
                                    <el-table-column :label="$t('seller.tableHead.discount')">
                                        <template slot-scope="scope">
                                            <p v-if="pageInfo.dis_type === 1">
                                                {{ $t('seller.singletreasure.discount1') }}<el-input
                                                    v-model="scope.row.dis_value" size="mini"
                                                    @input="handleSkuDiscount($event, scope.row.spec_id)"
                                                    oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)"
                                                    style="width: 68px;margin: 0 5px;"></el-input>{{ $t('seller.singletreasure.discount2') }}
                                            </p>
                                            <p v-else-if="pageInfo.dis_type === 2">
                                                {{ $t('seller.singletreasure.reduce') }}<el-input
                                                    v-model="scope.row.dis_value" size="mini"
                                                    @input="handleSkuDiscount($event, scope.row.spec_id)"
                                                    oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)"
                                                    style="width: 68px;margin: 0 5px;"></el-input>{{ $t('seller.unit.yuan') }}
                                            </p>
                                            <p v-else-if="pageInfo.dis_type === 3">
                                                {{ $t('seller.singletreasure.salePrice') + '：' }}<el-input
                                                    v-model="scope.row.dis_value" size="mini"
                                                    @input="handleSkuDiscount($event, scope.row.spec_id)"
                                                    oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)"
                                                    style="width: 68px;margin-left: 5px;"></el-input>
                                            </p>
                                            <p v-if="errorGoods[dialogGoodsId + '_' + scope.row.spec_id] === 1"
                                                class="table-error"><i
                                                    class="el-icon-circle-close"></i>{{ $t('seller.singletreasure.errorText1') }}
                                            </p>
                                            <p v-else-if="errorGoods[dialogGoodsId + '_' + scope.row.spec_id] === 2"
                                                class="table-error"><i
                                                    class="el-icon-circle-close"></i>{{ $t('seller.singletreasure.errorText2') }}
                                            </p>
                                            <p v-else-if="errorGoods[dialogGoodsId + '_' + scope.row.spec_id] === 3"
                                                class="table-error"><i
                                                    class="el-icon-circle-close"></i>{{ $t('seller.singletreasure.errorText3') }}{{
                                                        scope.row.origin_price }}{{ $t('seller.singletreasure.errorText4') }}</p>
                                            <p v-else-if="errorGoods[dialogGoodsId + '_' + scope.row.spec_id] === 4"
                                                class="table-error"><i
                                                    class="el-icon-circle-close"></i>{{ $t('seller.singletreasure.errorText5') }}
                                            </p>
                                            <p v-else-if="errorGoods[dialogGoodsId + '_' + scope.row.spec_id] === 5"
                                                class="table-error"><i
                                                    class="el-icon-circle-close"></i>{{ $t('seller.singletreasure.errorText6') }}
                                            </p>
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="$t('seller.tableHead.discountPrice')"
                                        prop="dis_price"></el-table-column>
                                </el-table>
                            </el-scrollbar>
                            <div slot="footer" class="dialog-footer">
                                <el-button @click="dialogVisibleSku = false" size="mini">{{ $t('seller.actions.cancel') }}</el-button>
                                <el-button type="primary" @click="handleConfirmSku" size="mini">{{ $t('seller.actions.confirm') }}</el-button>
                            </div>
                        </el-dialog>
                    </div>
                    <div class="panel-page" v-if="stepActive === 3">
                        <el-alert :title="$t('seller.successMsg.set')" type="success"
                            :description="$t('seller.singletreasure.successText')" :closable="false" show-icon>
                        </el-alert>
                        <router-link :to="{ name: 'singletreasureList' }"><el-button type="primary" size="mini">{{ $t('seller.actions.backList') }}</el-button></router-link>
                    </div>
                </div>
            </div>
        </el-card>
    </el-scrollbar>
</template>

<script>
import Pagination from '@/components/seller/pagination'
import { fetchTreasuresInfo, fetchTreasuresCategory, fetchTreasuresGoods, saveTreasuresGoods } from '@/api/seller/market'
export default {
    components: { Pagination },
    data() {
        return {
            stepActive: this.$route.query.type === 'success' ? 3 : 1,
            pageInfo: {},
            cateOptions: [],
            pageQuery: {
                single_treasure_id: this.$route.params.id,
                store_cate_id: -1,
                goods_status: '',
                goods_word: '',
                shop_code: '',
                is_show_sku: 1
            },
            listLoading: false,
            pageList: [],
            pageTotal: 0,
            pageCurr: 1,
            pageSize: 10,
            checkList: [],
            postGoods: {},
            errorGoods: {},
            dialogVisibleSku: false,
            dialogTitleSku: '',
            dialogGoodsId: 0,
            dialogDiscount: '',
            totakGoods: 0
        }
    },
    created() {
        this.getData()
    },
    methods: {
        goodsLoadError(e) {
            e.target.src = this.$imageErrorGoods;
            e.target.style.objectFit = 'fill';
        },
        async getData() {
            try {
                const cate = await fetchTreasuresCategory()
                this.cateOptions = cate.data.list
                const { data } = await fetchTreasuresInfo({ single_treasure_id: this.$route.params.id })
                this.pageInfo = data
                this.$set(this.pageQuery, 'is_show_sku', this.pageInfo.dis_level)
                this.getList()
            } catch (error) {
                console.log(error)
            }
        },
        getList() {
            this.listLoading = true
            const params = {
                ...this.pageQuery,
                page_index: (this.pageCurr - 1) * this.pageSize,
                page_size: this.pageSize
            }
            fetchTreasuresGoods(params).then(response => {
                const { data } = response
                this.pageList = data.list
                this.pageTotal = parseInt(data.count)
                this.listLoading = false
            })
        },
        handleSearch() {
            this.pageCurr = 1
            this.getList()
        },
        getRowKey(row) {
            return row.goods_id
        },
        handleNext() {
            this.checkList = Object.assign([], this.$refs.goodsTable.selection)
            if (this.checkList.length > 0) {
                var post_goods = {}
                var error_goods = {}
                this.checkList.forEach(item => {
                    if (this.postGoods[item.goods_id]) {
                        post_goods[item.goods_id] = this.postGoods[item.goods_id]
                    } else {
                        if (this.pageInfo.dis_level === 2) {
                            var specData = []
                            item.goods_specs.forEach(child => {
                                specData.push({ spec_id: child.spec_id, spec_1: child.spec_1, spec_2: child.spec_2, origin_price: child.price * 1, dis_type: this.pageInfo.dis_type, dis_value: '', dis_price: '' })
                                error_goods[item.goods_id + '_' + child.spec_id] = 0
                            })
                            post_goods[item.goods_id] = { erase_status: 0, spec_list: specData }
                        } else {
                            post_goods[item.goods_id] = { origin_price: item.price * 1, dis_type: this.pageInfo.dis_type, dis_value: '', dis_price: item.price * 1, limit: 0, erase_status: 0 }
                            error_goods[item.goods_id] = 0
                        }
                    }
                })
                this.postGoods = post_goods
                this.errorGoods = error_goods // 报错提示
                this.stepActive = 2
                this.$nextTick(() => {
                    this.$refs.checkTable.clearSelection()
                })
            } else {
                this.$message.error(this.$t('seller.errorMsg.selectGoods'))
            }
        },
        priceRange(id) {
            var goods = this.postGoods[id]
            if (this.pageInfo.dis_level === 2) {
                var max = 0
                var min = 0
                goods.spec_list.forEach(item => {
                    var price = item.dis_price || item.origin_price
                    if (max > 0) {
                        if (price > max) {
                            max = price
                        }
                    } else {
                        max = price
                    }
                    if (min > 0) {
                        if (price < min) {
                            min = price
                        }
                    } else {
                        min = price
                    }
                })
                if (max === min) {
                    return '￥' + max
                } else {
                    return '￥' + min + '~￥' + max
                }
            } else {
                return '￥' + goods.dis_price
            }
        },
        // 商品
        handleGoodsDiscount(val, id) {
            this.uploadGoodsPrice(id)
        },
        uploadGoodsPrice(id) {
            var goods = Object.assign({}, this.postGoods[id])
            this.errorGoods[id] = 0
            switch (this.pageInfo.dis_type) {
                case 1:
                    goods.dis_price = goods.origin_price * goods.dis_value / 10
                    if (goods.dis_value * 1 > 9.99 || goods.dis_value * 1 < 0.01) {
                        this.errorGoods[id] = 1
                    } else if (goods.dis_value * 1 < 7) {
                        this.errorGoods[id] = 4
                    }
                    break;
                case 2:
                    goods.dis_price = goods.origin_price - goods.dis_value * 1
                    if (goods.dis_value * 1 > goods.origin_price) {
                        this.errorGoods[id] = 2
                    } else if (goods.dis_price / goods.origin_price < 0.7) {
                        this.errorGoods[id] = 4
                    }
                    break;
                case 3:
                    goods.dis_price = goods.dis_value * 1
                    if (goods.dis_value * 1 > goods.origin_price || goods.dis_value * 1 < 0.01) {
                        this.errorGoods[id] = 3
                    } else if (goods.dis_price / goods.origin_price < 0.7) {
                        this.errorGoods[id] = 4
                    }
                    break;
            }
            if (goods.erase_status === 1) { // 取整
                goods.dis_price = parseInt(goods.dis_price)
            } else if (goods.erase_status === 2) { // 抹分
                goods.dis_price = parseFloat(goods.dis_price).toFixed(1)
            } else {
                goods.dis_price = parseFloat(goods.dis_price).toFixed(2)
            }
            this.postGoods[id] = Object.assign({}, goods)

        },
        handleGoodsErasure(id, type = 0) {
            if (this.pageInfo.dis_level === 2) {
                this.dialogGoodsId = id
                this.postGoods[id].erase_status = type
                this.dialogDiscount = ''
                this.uploadSkuAllPrice(0)
            } else {
                if (!this.errorGoods[id] > 0 && this.postGoods[id].dis_value) {
                    this.postGoods[id].erase_status = type
                    this.uploadGoodsPrice(id)
                }
            }
        },
        // SKU
        handleDialogVisibleSku({ row }) {
            const option = this.$t('seller.filters.discountType')
            this.dialogTitleSku = 'SKU' + option[this.pageInfo.dis_type] + '设置'
            this.dialogGoodsId = row.goods_id
            this.dialogDiscount = ''
            this.dialogVisibleSku = true
        },
        handleSkuDiscountAll(val) {
            this.uploadSkuAllPrice(1)
        },
        uploadSkuAllPrice(type) {
            this.postGoods[this.dialogGoodsId].spec_list.forEach(item => {
                this.errorGoods[this.dialogGoodsId + '_' + item.spec_id] = 0
                if (this.dialogDiscount && type === 1) {
                    item.dis_value = this.dialogDiscount
                }
                switch (this.pageInfo.dis_type) {
                    case 1:
                        item.dis_price = item.origin_price * item.dis_value / 10
                        if (item.dis_value * 1 > 9.99 || item.dis_value * 1 < 0.01) {
                            this.errorGoods[this.dialogGoodsId + '_' + item.spec_id] = 1
                        } else if (item.dis_value * 1 < 7) {
                            this.errorGoods[this.dialogGoodsId + '_' + item.spec_id] = 4
                        }
                        break;
                    case 2:
                        item.dis_price = item.origin_price - item.dis_value * 1
                        if (item.dis_value * 1 > item.origin_price) {
                            this.errorGoods[this.dialogGoodsId + '_' + item.spec_id] = 2
                        } else if (item.dis_price / item.origin_price < 0.7) {
                            this.errorGoods[this.dialogGoodsId + '_' + item.spec_id] = 4
                        }
                        break;
                    case 3:
                        item.dis_price = item.dis_value * 1
                        if (item.dis_value * 1 > item.origin_price || item.dis_value * 1 < 0.01) {
                            this.errorGoods[this.dialogGoodsId + '_' + item.spec_id] = 3
                        } else if (item.dis_price / item.origin_price < 0.7) {
                            this.errorGoods[this.dialogGoodsId + '_' + item.spec_id] = 4
                        }
                        break;
                }
                if (this.postGoods[this.dialogGoodsId].erase_status === 1) { // 取整
                    item.dis_price = parseInt(item.dis_price)
                } else if (this.postGoods[this.dialogGoodsId].erase_status === 2) { // 抹分
                    item.dis_price = parseFloat(item.dis_price).toFixed(1)
                } else {
                    item.dis_price = parseFloat(item.dis_price).toFixed(2)
                }
            })
        },
        handleSkuDiscount(val, specId) {
            this.postGoods[this.dialogGoodsId].spec_list.forEach(item => {
                if (item.spec_id === specId) {
                    this.errorGoods[this.dialogGoodsId + '_' + specId] = 0
                    switch (this.pageInfo.dis_type) {
                        case 1:
                            item.dis_price = item.origin_price * item.dis_value / 10
                            if (item.dis_value * 1 > 9.99 || item.dis_value * 1 < 0.01) {
                                this.errorGoods[this.dialogGoodsId + '_' + item.spec_id] = 1
                            } else if (item.dis_value * 1 < 7) {
                                this.errorGoods[this.dialogGoodsId + '_' + item.spec_id] = 4
                            }
                            break;
                        case 2:
                            item.dis_price = item.origin_price - item.dis_value * 1
                            if (item.dis_value * 1 > item.origin_price) {
                                this.errorGoods[this.dialogGoodsId + '_' + item.spec_id] = 2
                            } else if (item.dis_price / item.origin_price < 0.7) {
                                this.errorGoods[this.dialogGoodsId + '_' + item.spec_id] = 4
                            }
                            break;
                        case 3:
                            item.dis_price = item.dis_value * 1
                            if (item.dis_value * 1 > item.origin_price || item.dis_value * 1 < 0.01) {
                                this.errorGoods[this.dialogGoodsId + '_' + item.spec_id] = 3
                            } else if (item.dis_price / item.origin_price < 0.7) {
                                this.errorGoods[this.dialogGoodsId + '_' + item.spec_id] = 4
                            }
                            break;
                    }
                    if (this.postGoods[this.dialogGoodsId].erase_status === 1) { // 取整
                        item.dis_price = parseInt(item.dis_price)
                    } else if (this.postGoods[this.dialogGoodsId].erase_status === 2) { // 抹分
                        item.dis_price = parseFloat(item.dis_price).toFixed(1)
                    } else {
                        item.dis_price = parseFloat(item.dis_price).toFixed(2)
                    }
                }
            })
        },
        handleDialogErasure(type) {
            this.postGoods[this.dialogGoodsId].erase_status = type
            this.uploadSkuAllPrice(0)
        },
        handleConfirmSku() {
            var flag = true
            this.postGoods[this.dialogGoodsId].spec_list.forEach(item => {
                if (this.errorGoods[this.dialogGoodsId + '_' + item.spec_id] > 0) {
                    flag = false
                }
                if (!item.dis_value) {
                    this.errorGoods[this.dialogGoodsId + '_' + item.spec_id] = 5
                    flag = false
                }
            })
            if (flag) {
                this.dialogVisibleSku = false
            }
        },
        // 操作
        handleWithdrawal({ row }) {
            this.$confirm(this.$t('seller.confirm.withdrawalGoodsText'), '', {
                confirmButtonText: this.$t('seller.actions.confirm'),
                cancelButtonText: this.$t('seller.actions.cancel'),
                type: 'warning'
            }).then(() => {
                delete (this.postGoods[row.goods_id])
                delete (this.errorGoods[row.goods_id])
                this.checkList.splice(this.checkList.indexOf(row), 1)
            }).catch(() => { })
        },
        handlePrev() {
            this.stepActive = 1
            this.$nextTick(() => {
                this.checkList.forEach(row => {
                    this.$refs.goodsTable.toggleRowSelection(row)
                })
            })
        },
        handleSave() {
            var sum = 0
            if (this.pageInfo.dis_level === 2) {
                // 折扣
                for (const key in this.postGoods) {
                    var flag = false
                    this.postGoods[key].spec_list.forEach(item => {
                        if (!item.dis_value) {
                            this.errorGoods[key + '_' + item.spec_id] = 5
                            flag = true
                        } else if (this.errorGoods[key + '_' + item.spec_id] > 0) {
                            flag = true
                        }
                    })
                    if (flag) {
                        sum++
                    }
                }
            } else {
                // 商品
                for (const key in this.postGoods) {
                    if (!this.postGoods[key].dis_value) {
                        this.errorGoods[key] = 5
                        sum++
                    } else if (this.errorGoods[key] > 0) {
                        sum++
                    }
                }
            }
            if (sum > 0) {
                this.$alert(this.$t('seller.alert.finishSet1') + sum + this.$t('seller.alert.finishSet2'), '', {
                    confirmButtonText: this.$t('seller.actions.confirm'),
                    type: 'error'
                })
                return false
            }
            const params = {
                join_goods: this.postGoods,
                single_treasure_id: this.pageInfo.single_treasure_id
            }
            params.join_goods = JSON.stringify(params.join_goods)
            saveTreasuresGoods(params).then(() => {
                this.stepActive = 3
                this.$router.replace({ name: 'singletreasureAddItem', query: { type: 'success' } })
            })
        },
        handleTableChange(val, row) {
            var sum = 0
            if (this.pageInfo.dis_level === 2) {
                val.forEach(item => {
                    sum += item.spec_count
                })
            } else {
                sum = val.length
            }
            if (sum > 1200) {
                this.totakGoods = 1200
                this.$refs.goodsTable.toggleRowSelection(row)
            } else {
                this.totakGoods = sum
            }
        },
        handleTableChangeAll(val) {
            var sum = 0
            if (this.pageInfo.dis_level === 2) {
                val.forEach(item => {
                    sum += item.spec_count
                })
            } else {
                sum = val.length
            }
            if (sum > 1200) {
                this.totakGoods = 1200
                if (this.pageInfo.dis_level === 2) {
                    var n = 0
                    for (let index = 0; index < val.length; index++) {
                        n += val[index].spec_count
                        if (n > 1200) {
                            this.$refs.goodsTable.toggleRowSelection(val[index])
                        }
                    }
                } else {
                    for (let index = 1200; index < val.length; index++) {
                        this.$refs.goodsTable.toggleRowSelection(val[index])
                    }
                }
            } else {
                this.totakGoods = sum
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.seller-panel {

    .panel-steps {
        padding: 20px 0;
        margin-bottom: 12px;
        border-bottom: 1px solid #e6e6e6;
    }

    .panel-hd {
        margin-bottom: 12px;
        border-bottom: 1px solid #e6e6e6;
    }

    .panel-bd {
        border-bottom: 1px solid #e6e6e6;
    }

    .panel-main {
        .panel-title {
            font-size: 14px;
            line-height: 42px;
        }

        .panel-page {

            .goods-item {
                display: flex;
                min-width: 200px;

                .item-pic {
                    flex-shrink: 0;
                    width: 52px;
                    height: 52px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .item-main {
                    flex: 1;
                    width: 0;
                    margin-left: 10px;

                    .name {
                        min-height: 32px;
                        font-size: 12px;
                        line-height: 16px;
                        margin-bottom: 5px;

                        a {
                            color: #5584ff;
                        }
                    }

                    .text {
                        font-size: 12px;
                        line-height: 20px;
                    }
                }
            }

            .page-bottom {
                .bottom-hd {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .selected {
                        margin-top: 15px;
                    }
                }
                .bottom-bd {
                    margin-top: 20px;
                }
            }
        }
    }
}

::v-deep .el-form {
    .el-form-item__label {
        font-size: 12px;
    }

    .el-form-item__content {
        font-size: 12px;
    }
}

::v-deep .el-checkbox {
    .el-checkbox__label {
        font-size: 12px;
    }
}

.table-error {
    margin-top: 5px;
    display: inline-block;
    padding: 5px;
    line-height: 1.5;
    background-color: #fae7e7;

    [class^=el-icon-] {
        color: #ff3333;
        margin-right: 5px;
    }
}

.dialog-sku {
    max-height: 600px;

    .dialog-head {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        justify-content: space-between;
    }
}

::v-deep .el-alert {
    &.el-alert--success {
        padding: 12px 0;
        margin-bottom: 40px;
        background-color: transparent;

        .el-alert__icon {
            font-size: 40px;
            width: 40px;
        }

        .el-alert__content {
            color: #333;

            .el-alert__title {
                font-size: 16px;
            }

            .el-alert__description {
                color: #333;
                font-size: 14px;
                margin-top: 10px;
            }
        }
    }
}</style>
